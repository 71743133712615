<template>
  <div class="salary-slider">
    <span class="salary-slider__label">Bruttojahresgehalt</span>
    <v-range-slider
      v-model="salaryRange"
      min="1"
      max="300"
      class="mt-1"
      hide-details
      :disabled="noSalary"
    >
      <template v-slot:prepend>
        {{ (salaryRange[0] * 1000).toLocaleString() }}€
      </template>

      <template v-slot:append>
        {{ (salaryRange[1] * 1000).toLocaleString() }}€
      </template>
    </v-range-slider>

    <div v-if="this.item.fieldValidationMode !== 2">
      <v-checkbox
        v-model="noSalary"
        :label="$t('application.noSalaryLabel')"
        hide-details
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    value: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: () => ({
    salaryRange: [30, 50],
    noSalary: false
  }),

  computed: {
    fieldLabel() {
      let label = this.localize(this.item.label);

      // TODO: use enumeration
      if (this.item.fieldValidationMode === 2) {
        label += '*';
      }

      return label;
    },

    fieldValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    rules() {
      if (this.item.fieldValidationMode !== 2) {
        return [];
      }

      return [
        (value) => {
          if (!value || value.trim() === '') {
            return this.$t('application.fieldIsMandatory');
          }

          return true;
        }
      ];
    }
  },

  watch: {
    salaryRange() {
      this.emitValue();
    },

    noSalary() {
      if (this.noSalary) {
        this.$emit('input', '');
      } else {
        this.emitValue();
      }
    }
  },

  mounted() {
    this.emitValue();
  },

  methods: {
    emitValue() {
      const minSalary = this.salaryRange[0].toLocaleString() * 1000 + '€';
      const maxSalary = this.salaryRange[1].toLocaleString() * 1000 + '€';

      if (minSalary === maxSalary) {
        this.$emit('input', maxSalary);
      } else {
        this.$emit('input', `${minSalary} - ${maxSalary}`);
      }
    }
  }
};
</script>
